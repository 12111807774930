import React from 'react'
import styled from 'styled-components';

const OurStory = () => {
    return (
        <Container>
            <ContainerHeader>Our Story</ContainerHeader>
            <Wrapper >
                <ContainerP data-sal="slide-up" data-sal-easing="ease">Having worked in the healthcare industry for close to 10 years as a hospital pharmacist in <a href="https://www.jointcommissioninternational.org/" target="_blank" rel="noopener noreferrer">JCIA</a> accredited hospitals.</ContainerP>
                <ContainerP data-sal="slide-up" data-sal-easing="ease">I observed that most challenges in the industry can be solved by a smart Information Technology system.</ContainerP>
                <ContainerP data-sal="slide-up" data-sal-easing="ease">I have always had a passion for IT that pushed me to do software development and data science that led to the birth of Tripleaim Software Company.</ContainerP>
                <ContainerP data-sal="slide-up" data-sal-easing="ease">I founded Tripleaim Software having been inspired by a course I did on <a href="http://www.ihi.org/" target="_blank" rel="noopener noreferrer">Quality Improvement and Patient Safety by IHI</a>.</ContainerP>
                <ContainerP data-sal="slide-up" data-sal-easing="ease">How can we use technology to improve patient experience, population health and reduce healthcare cost?</ContainerP>
                <ContainerP data-sal="slide-up" data-sal-easing="ease">We bring a team of professionals to build these efficient, effective and safe software solutions in collaboration with healthcare providers.</ContainerP>
            </Wrapper>
        </Container>
    )
}

export default OurStory

const Container = styled.div`
    height: 100%;
    width: 90%;
    background-color: #fff;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: auto;
    margin-left: auto;
    

    @media screen and (min-width: 1000px) {
        width: 75%; 
    }

    @media screen and (min-width: 1700px) {
        width: 50%; 
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 1rem 0rem;
    border-radius: 10%;
    border: medium solid #5EA3B8;
    box-shadow: 3px 3px 3px 3px #5EA3B8;
`

const ContainerHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem;
    padding: 2rem;
`
const ContainerP = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    max-width: 100%;
    height: 100%;
    margin-bottom: 2rem;
    padding: 0rem 1rem;
    line-height: 3rem;
    
`

