import React from 'react'
import { AiFillPlusCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { whyWeDoData } from './../../data/about/Whatwedo';

const Whywedo = () => {
    return (
        <Container>
            <ContainerHeader>Why we do it</ContainerHeader>
            <ContainerP>We believe that:</ContainerP>
            <ContainerList data-sal="zoom-in" data-sal-easing="ease">
                {whyWeDoData.map((items, index) => (
                    <InfoCard key={index}>
                        <IconImg><AiFillPlusCircle /></IconImg>
                        <ListItems>{items}</ListItems>
                    </InfoCard>
                ))}
            </ContainerList>
        </Container>
    )
}

export default Whywedo

const Container = styled.div`
    min-height: 50vh;
    color: #000;
    
`
const ContainerHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem;
    padding: 2rem;
       
`
const ContainerP = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: left;
    margin-bottom: 3rem;
    padding: 0rem 1rem;
`

const ContainerList = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
   
    @media screen and (min-width: 800px) {
        flex-direction: row;
    }

`
const InfoCard = styled.div`
    display: flex;
    margin-bottom: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;

    :hover {
        transform: scale(1.05)
    }
`

const ListItems = styled.div`
    text-align: left;
    font-size: clamp(1rem,2vw, 1.25rem);
           

    @media screen and (min-width: 800px) {
        border-radius: 6%;
        border: medium solid #5EA3B8;
        width: 40vw;
        height: 100%;
        background: #F1F1F1;
        display: block;
        text-align: center;
        
    }
    @media screen and (min-width: 1000px) {
        width: 20vw;
        
    }
    
`
const IconImg = styled.div`
    font-size: clamp(1rem,2vw, 1.25rem);
    padding: 0rem 1rem;

    @media screen and (min-width: 800px) {
        display: none;
    }
`