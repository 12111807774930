import React from 'react'
import styled from 'styled-components';
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"



const Team = () => {
  const data = useStaticQuery(graphql`
    query TeamQuery {
        allTeamImgJson {
          edges {
            node {
              alt
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              
            }
          }
        }
        allTeamImgJson {
          edges {
            node {
              alt1
              img1 {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              
            }
          }
        }
      }
      
    `)
  return (
    <Container>
      <ContainerHeader>Our Team</ContainerHeader>
      <ContainerP>Meet Our Team</ContainerP>
      <Cards>
        <MemberCard>
          <Image
            alt={data.allTeamImgJson.edges[0].node.alt}
            fluid={data.allTeamImgJson.edges[0].node.img.childImageSharp.fluid}>
          </Image>
          <MemberTitle>Director</MemberTitle>
          <MemberName>Dr Ibrahim Okich</MemberName>
        </MemberCard>
        <MemberCard>
          <Image
            alt={data.allTeamImgJson.edges[1].node.alt1}
            fluid={data.allTeamImgJson.edges[1].node.img1.childImageSharp.fluid}>
          </Image>
          <MemberTitle>Director</MemberTitle>
          <MemberName>Dr Bevin Likuyani</MemberName>
        </MemberCard>
        {/* <MemberCard>
          <Image
            alt={data.allTeamImgJson.edges[1].node.alt1}
            fluid={data.allTeamImgJson.edges[1].node.img1.childImageSharp.fluid}>
          </Image>
          <MemberTitle>Developer</MemberTitle>
          <MemberName>Ezra Ndungu</MemberName>
        </MemberCard> */}
        <MemberCard>
          <Image
            alt={data.allTeamImgJson.edges[1].node.alt1}
            fluid={data.allTeamImgJson.edges[1].node.img1.childImageSharp.fluid}>
          </Image>
          <MemberTitle>Digital Marketer</MemberTitle>
          <MemberName>Catherine Gakari</MemberName>
        </MemberCard>
      </Cards>
    </Container>
  )
}

export default Team

const Container = styled.div`
    height: 100%;
    padding: 1rem 1rem;
    background: #E5E5E5;
    color: #000;
  `
const Cards = styled.div`
   display:flex;
   flex-wrap: wrap;
`
const ContainerHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem; 
`
const ContainerP = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    margin-bottom: 2rem; 
`
const MemberCard = styled.div`
    background: #fff;  
    width: 100%;
    height 250px;
    max-width: 200px;
    min-width: 150px;
    border-radius: 10%;
    diplay: flex;
    margin-right: auto;
    margin-left: auto;
    margin-bottom:10px;
`
const Image = styled(Img)`
    width:65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;

`
const MemberTitle = styled.h3`
    font-size: clamp(1.2rem,3vw, 1.5rem);
    text-align: center;
    margin-bottom: 2rem;
`
const MemberName = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    padding-bottom: 2rem;
`


