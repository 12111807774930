import React from 'react'
import styled from 'styled-components';

const ABoutBody = () => {
    return (
        <WhoweareContainer>
            <ContainerHeader>Who we are</ContainerHeader>
            <ContainerP>We are a software company focusing on improving healthcare businesses through artificial intelligent software solutions.</ContainerP>
            <MisVisContainer>
                <SubContainer>
                    <VisHeader>Our Vision</VisHeader>
                    <VisP>To contribute to the quality of life by making tools that reduce healthcare cost</VisP>
                </SubContainer>
                <SubContainer>
                    <MisHeader>Our Mission</MisHeader>
                    <MisP>To strengthen health systems using intelligent software solutions</MisP>
                </SubContainer>
            </MisVisContainer>
        </WhoweareContainer>
    )
}

export default ABoutBody

const WhoweareContainer = styled.div`
    min-height: 50vh;
    background-color: #fff;
    padding: 5rem 1rem;
    margin-top: 2rem;
    

    @media screen and (min-width: 1200px) {
        min-height: 80vh;
        margin-top: 5rem;
    }
`
const ContainerHeader = styled.h1`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem;
`
const ContainerP = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
`
const MisVisContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    justify-items: center;
    padding: 1rem 2rem;
    

    @media screen and (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 15rem;
    
    }
`
const SubContainer = styled.div`
    max-width: 80%;
  
`
const MisHeader = styled.h3`
    text-align: center;
    font-size: clamp(1.2rem,3vw, 1.5rem);
    margin-bottom: 1rem;
    
`
const MisP = styled.p`
font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
`

const VisHeader = styled.h3`
    text-align: center;
    font-size: clamp(1.2rem,3vw, 1.5rem);
    margin-bottom: 1rem;
`
const VisP = styled.p`
font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
`

