import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';
import Img from 'gatsby-image'

const CoreValues = () => {
    const data = useStaticQuery(graphql`
    query CoreValuesQuery {
        allCoreValuesJson {
          edges {
            node {
              alt
              description
              name
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      
    `)


    const getValues = (data) => {
        const valuesArray = []
        data.allCoreValuesJson.edges.forEach((element, index) => {
            valuesArray.push(
                <ValuesCard key={index}>
                    <IconImg
                        alt={element.node.alt}
                        fluid={element.node.img.childImageSharp.fluid}>
                    </IconImg>
                    <CardHeader>{element.node.name}</CardHeader>
                    <CardP>{element.node.description}</CardP>
                </ValuesCard>
            )
        })
        return valuesArray
    }

    return (
        <Container>
            <CoreValuesHeader>Core Values</CoreValuesHeader>
            <Wrapper data-sal="slide-down" data-sal-easing="ease">{getValues(data)}</Wrapper>
        </Container>
    )
}

export default CoreValues

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 2rem;
    align-items: center;
`
const CoreValuesHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem;
    padding: 2rem;
`
const Wrapper = styled.div`
    display: grid;
    grid-gap: 1rem;
    padding: 1rem 0rem;
    margin-bottom: 1rem;
    grid-template-columns: 1fr;
   
    @media screen and (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    
`
const ValuesCard = styled.div`
    border: medium solid #7FDBF7;
    border-radius: 5%;
    width: 80%;
    height: 100%;
    justify-self: center;

    :hover {
        transform: scale(1.05)
    }
    
`
const IconImg = styled(Img)`
    width: 10%;     
    border-radius: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
`
const CardHeader = styled.h3`
    text-align: center;
    font-size: clamp(1.2rem,3vw, 1.5rem);
    margin-bottom: 1rem; 
`
const CardP = styled.p`
font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    margin-bottom: 2rem;
`
