export const whatWeDoData = [
    "Expanding our clients, market share through digital marketing",
    "Promoting our client’s brand through positioning in our e-commerce platforms.",
    "Building software that automates day-to-day tasks to save our clients on time, cost and improves efficiency.",
    "Providing actionable insight from our client’s data through data analytics using statistical software tools.",
    "Providing predictions and decision support tools based on our client’s data."
]

export const whyWeDoData = [
    "Healthcare processes automation can increase efficiency and productivity at a lower cost ",
    "We can derive more actionable insight from the data being generated from the software applications we use",
    "We  can predict the future using historical and current data to improve decision making that affect health outcome",
    "Creating awareness about your services can increase your revenue, promote accessibility and better health outcomes"
]