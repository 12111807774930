import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import AboutHero from '../components/about/AboutHero'
import ABoutBody from '../components/about/ABoutBody'
import WhatWeDo from '../components/about/WhatWeDo'
import OurStory from '../components/about/OurStory'
import Whywedo from '../components/about/Whywedo'
import CoreValues from '../components/about/CoreValues'
import Team from '../components/about/Team'
import Proposal from '../components/Proposal'


const About = () => (
  <Layout>
    <SEO title="About us - software company in kenya and data analytics kenya"
      description="We build intelligent software solutions for automation, provide data insights through data analysis and business dashboards. Market your business online."
      keywords="health technology in Kenya, health system in Kenya, health information system in Kenya, digital health Kenya, Triple aim, quality of care in Kenya, Software company in Kenya, Data Analytics company Kenya, artificial intelligence company Kenya,
              web design kenya, digital marketing in kenya, data analytics, it companies in kenya, software kenya, health system management, dashboard, mobile apps, Hospital management software, data analysis companies in kenya "
      image="https://www.tripleaimsoftware.com/static/acfdf7614f8c0271937cdce4d37373c5/ee604/blm.png"
      siteUrl="https://www.tripleaimsoftware.com/about/"
      canonical="https://www.tripleaimsoftware.com/about/"
    >
    </SEO>
    <AboutHero />
    <ABoutBody />
    <WhatWeDo />
    <OurStory />
    <Whywedo />
    <CoreValues />
    <Team />
    <Proposal />
  </Layout>
)

export default About



