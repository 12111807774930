import React from 'react'
import styled from 'styled-components';
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import { BsChevronDoubleDown } from 'react-icons/bs';


const AboutHero = () => {
    const data = useStaticQuery(graphql`
    query AboutQuery {
        allAboutDataJson {
          edges {
            node {
                alt
                img {
                 childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `)

    const getServices = (data) => {
        const serviceArray = []
        data.allAboutDataJson.edges.forEach((element, index) => {
            serviceArray.push(
                <div key={index}>
                    <ServiceImg
                        alt={element.node.alt}
                        fluid={element.node.img.childImageSharp.fluid}>
                    </ServiceImg>
                    <Arrow />
                </div>
            )
        });
        return serviceArray
    }

    return (
        <AboutContainer>{getServices(data)}</AboutContainer>
    )
}

export default AboutHero

const AboutContainer = styled.div`
    background: #000;
    margin-top: 70px;
    padding: 1rem 1rem;
`
const ServiceImg = styled(Img)`
    width:100%;
    margin-left: auto;
    margin-right: auto; 
    
    @media screen and (min-width: 600px) {
        width:65%;
        margin-left: auto;
        margin-right: auto; 
    }
    
`
const Arrow = styled(BsChevronDoubleDown)`

@media screen and (min-width: 1350px) {
    font-size: 1.8rem;
    color: #fff;
    position: absolute;
    top: 45.5rem;
    right: 0;
}  
  
 `