import React from 'react'
import { AiFillPlusCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { whatWeDoData } from './../../data/about/Whatwedo';

const WhatWeDo = () => {
    return (
        <Container>
            <ContainerHeader>What we do</ContainerHeader>
            <ContainerP>We assist in improving healthcare businesses by:</ContainerP>
            {whatWeDoData.map((items, index) => (
                <ContainerList data-sal="slide-up" data-sal-easing="ease" key={index}>
                    <IconImg><AiFillPlusCircle /></IconImg>
                    <ListItems>{items}</ListItems>
                </ContainerList>
            ))}
        </Container>
    )
}

export default WhatWeDo

const Container = styled.div`
    min-height: 80vh;
    background-color: #1D3238;
    color: #fff;
    
`
const ContainerHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem;
    padding: 2rem;
       
`
const ContainerP = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    max-width: 100%;
    margin-bottom: 5rem;
    padding: 0rem 1rem;
`
const ContainerList = styled.div`
    display: flex;
    min-height:10vh;
    margin-bottom: 1rem;
    justify-content: center;
    color: #fff;
    align-items: flex-start;
    flex: 1;
    
`
const ListItems = styled.div`
    padding: 0rem 1rem;
    text-align: center;
    font-size: clamp(1rem,2vw, 1.25rem);
    
`
const IconImg = styled.div`
    font-size: clamp(1rem,2vw, 1.25rem);
`